export const questions = [
    {
        id:1,
        text:'لطفا اسمی که من باید باهاش صدات کنم رو بهم بگو'
    },
    {
        id:2,
        text:'بعد جواب هایی که بهم میدیو به کدوم ایمیلت ارسال کنم؟'
    },
    {
        id:3,
        text:'شماره تلفنی که دوست داری به این حساب وصل شه چیه؟'
    },
    {
        id:4,
        text:'میشه سنت رو بهم بگی؟'
    },
    {
        id:5,
        text:'برای اومدن به کانادا باید زبان انگلیسی یا فرانسه بدونی. کدوم زبانت قوی تره؟'
    },
    {
        id:6,
        text:'تاحالا امتحان آیلتس دادی یا نه؟'
    },
    {
        id:7,
        text:'نمره آیلتست چند یود؟'
    },
    {
        id:8,
        text:'بعضی روش های مهاجرتی نمره ی مشخصی تو هر مهارت نیاز دارن. فکر میکنی اگه یکم وقت بذاری بهترین نمره ای که میتونی توی آیلتس جنرال بگیری چیه؟'
    },
    {
        id:9,
        text:'مدرک تحصیلی و رشته دقیق تحصیلیت چیه؟'
    },
    {
        id:10,
        text:'سابقه کار ده سال اخیرت چی بوده؟'
    },
    {
        id:11,
        text:'توی کدوم یکی از رشته های زیر نامه معتبر سابقه کار میتونی ارائه بدی؟'
    },
    {
        id:12,
        text:'برای اینکه بشه برات ویزای خوب و مطمئنی بگیرم باید بتونم شرایط مالی و اقتصادیت رو نشون بدم که در کشور خودت جا افتاده هستی و ریشه داری. دارایی هایی که میتونی با سند و مدرک نشون بدی کدوما هستن؟'
    },
    {
        id:13,
        text:'آیا شما شخصیت شناخته شده و معروفی در سطح ملی ایران هستید؟'
    },
    {
        id:14,
        text:'تاحالا هیچ وقت خودت یا زیرمجموعه شخص دیگه ای برای کانادا اقدام کردی؟'
    },
    {
        id:15,
        text:'راستی نپرسیدم. اقامت کشور دیگه ای  رو هم داری؟'
    },
    {
        id:16,
        text:'در 10 سال گذشته سفر خارجی دیگری هم داشتی؟'
    },
    {
        id:17,
        text:'تو کانادا فامیل یا آشنایی داری؟'
    },
    {
        id:18,
        text:'راستی یادم رفت بپرسم وضعیت تاهلت چیه؟'
    },
    {
        id:19,
        text:'فرزند هم داری؟'
    },
    {
        id:20,
        text:'خیلی برات مهمه که در اولین ورودت همه خانوادت رو هم با خودت بیاری؟ ممکنه تو شرایط خاصی این لزوما  برای گرفتن ویزا کمکت نکنه ...'
    },
    {
        id:21,
        text:'حتی با وجود اینکه نخوای برای خانوادت هم اقدام کنی ولی اگه اطلاعاتشون رو بتونی بهم بدی  خیلی برای پیشنهادی که میتونم بهت بدم کمکم میکنی'
    },
]
