import './App.css';
import StartEvaluation from "./components/StartEvaluation";
import {Route, Routes} from "react-router";
import FirstStep from "./components/steps/FirstStep";
import MainForm from "./components/MainForm";
import SecondStep from "./components/steps/SecondStep";
import Wrapper from "./components/steps";
import BuyService from "./components/BuyService";


function App() {
  return (
    <div className="App">
        <Routes>
            <Route path={'/'} element={<StartEvaluation />} />
            <Route path={'/partner'} element={<StartEvaluation />} />
            <Route path={'questions'} element={<MainForm />}>
                <Route path={':step'} element={<Wrapper />} />
                {/*<Route path={'questions/partner/1'} element={<FirstStep />} />*/}
                {/*<Route path={'questions/2'} element={<SecondStep />} />*/}
                {/*<Route path={'questions/partner/2'} element={<SecondStep />} />*/}
            </Route>
            <Route path={'questions/partner'} element={<MainForm />}>
                <Route path={':step'} element={<Wrapper />} />
                {/*<Route path={'questions/partner/1'} element={<FirstStep />} />*/}
                {/*<Route path={'questions/2'} element={<SecondStep />} />*/}
                {/*<Route path={'questions/partner/2'} element={<SecondStep />} />*/}
            </Route>
            <Route path={'buy-service'} element={<BuyService />} />
        </Routes>
    </div>
  );
}

export default App;
