import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import SixthStep from "./SixthStep";
import SeventhStep from "./SeventhStep";
import EighthStep from "./EighthStep";
import NinthStep from "./NinthStep";
import TenthStep from "./TenthStep";
import EleventhStep from "./EleventhStep";
import TwelfthStep from "./TwelfthStep";
import ThirteenthStep from "./ThirteenthStep";
import FourteenthStep from "./FourteenthStep";
import FifteenthStep from "./FifteenthStep";
import SixteenthStep from "./SixteenthStep";
import SeventeenthStep from "./SeventeenthStep";
import EighteenthStep from "./EighteenthStep";
import NineteenthStep from "./NineteenthStep";
import TwentiethStep from "./TwentiethStep";
import TwentyOneStep from "./TwentyOneStep";
import {useLocation, useParams} from "react-router";

const Wrapper = () => {

    const params = useParams()

    switch (Number(params.step)) {
        case 1:
            return <FirstStep/>
        case 2:
            return <SecondStep/>
        case 3:
            return <ThirdStep/>
        case 4:
            return <FourthStep/>
        case 5:
            return <FifthStep/>
        case 6:
            return <SixthStep/>
        case 7:
            return <SeventhStep/>
        case 8:
            return <EighthStep/>
        case 9:
            return <NinthStep/>
        case 10:
            return <TenthStep/>
        case 11:
            return <EleventhStep/>
        case 12:
            return <TwelfthStep/>
        case 13:
            return <ThirteenthStep/>
        case 14:
            return <FourteenthStep/>
        case 15:
            return <FifteenthStep/>
        case 16:
            return <SixteenthStep/>
        case 17:
            return <SeventeenthStep/>
        case 18:
            return <EighteenthStep/>
        case 19:
            return <NineteenthStep/>
        case 20:
            return <TwentiethStep/>
        case 21:
            return <TwentyOneStep/>
        default :
            return <FirstStep/>
    }
};

export default Wrapper