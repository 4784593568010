import './startEvaluation.scss'
import CustomButton from "../general/CustomButton";
import {useNavigate,useLocation} from "react-router";

const StartEvaluation = ()=>{

    const navigate = useNavigate()
    const location = useLocation()

    const goToQuestionsHandler = ()=>{
        if (location.pathname.includes('partner')){
            navigate('/questions/partner/1')
        }else{
            navigate('/questions/1')
        }

    }

    const renderTitle = ()=>{
        if (location.pathname.includes('partner')){
            return 'لطفا سوالاتی که پاسخ دادی رو این بار برای همسرت تکمیل کن'
        }else{
            return 'ارزیابی هوشمند جاب آفر کانادا'
        }
    }

    return(
        <div className={'start-page-container'}>
            <div className={'start-page-content-wrapper'}>
            <div className={'start-page-title'}>{renderTitle()}</div>
            <CustomButton text={'شروع کنید'} width={200} onClick={goToQuestionsHandler}/>
            </div>
        </div>
    )
}

export default StartEvaluation